import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import ReservoirLevels from '../components/chart-powell-mead';
import Scablands from '../images/scablands.jpeg';
import Michigan from '../images/michigan-levels.png';
import Compact from '../images/compact.png';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import WaterOpenGraphImage from '../images/water-social.png';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "reservoir-empty.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Here Today, Gone Tomorrow: Water in a Destabilized Climate" description="Once dependable water sources are growing more volatile in a destabilized climate." image={'https://storybook.earth' + WaterOpenGraphImage} pageCanonical={'https://storybook.earth/water'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">Once dependable water sources are growing more volatile in a destabilized climate.</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">Here Today, Gone Tomorrow: Water in a Destabilized Climate</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Sep 01, 2021 | 8 min read</p>
    </div>
      </div>
      <p>{`Water on Earth is in constant motion—falling from the sky, eroding mountains, carving canyons, and draining into the oceans that circulate around the planet. Even lakes and ponds, static to our eyes but for the ripple of waves on their surface, are always on the move. Much of a lake’s volume will evaporate into the atmosphere each year, while precipitation and runoff from streams replenish it.  `}</p>
      <p>{`When the inflow of water equals the outflow, the system is in a state of stability. Stable water systems attract communities of life all around the planet, which grow to rely on them for survival. And in that reliance it becomes easy to forget that when conditions change, even just a little, stability can break down quickly.`}</p>
      <p>{`Few examples were more cataclysmic than the sudden collapse of Glacial Lake Missoula 15,000 years ago `}<span className="sup">{`[1]`}</span>{`. This ice age lake, which contained about as much water as Lakes Erie and Ontario combined, was created after an extension of the continental ice sheet drifted down from Canada and walled off the mountain valleys of western Montana, filling them with glacial meltwater at depths of up to 950 feet `}<span className="sup">{`[2]`}</span>{`.`}</p>
      <p>{`As the planet warmed its way out of the ice age toward pre-industrial stability, the thawing ice dam which contained Glacial Lake Missoula burst open suddenly. It took about two days for the massive lake to drain, bulldozing a network of channels across much of eastern Washington State as lake water flooded 430 miles westward to the Pacific Ocean `}<span className="sup">{`[3]`}</span>{`. The destruction caused by the flood is still visible over a vast area of the Pacific Northwest, known today as the Channeled Scablands.`}</p>
      <div className="mtxxl txt-center">
        <img src={Scablands} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">The Mattawa Boulder-Bar Fan shows rock debris left behind after the draining of Glacial Lake Missoula and preceding ice age megafloods. Many of these boulders were ripped from the sides of Sentinel Gap (top right) and deposited at this location 5 miles downstream <span className="sup">[4]</span>.</p>
      </div>
      <p>{`While today there is no sudden catastrophe lying in wait that rivals the flood of Glacial Lake Missoula, previously stable water systems all around the planet will be jostled by a changing climate. Instability is anathema to civilization as we know it, as entrenched infrastructure relies on predictability. The stakes are high, and communities both rural and urban will be stressed to their limits as water on a warming planet becomes increasingly volatile.`}</p>
      <p>{`In Chicago, the future of surrounding water systems is uncertain and precarious `}<span className="sup">{`[5]`}</span>{`. The city rests on a former swamp strategically positioned to access both the Great Lakes and Mississippi River waterways. Its location continues to drive economic growth, but also makes it vulnerable to abrupt change. Chicago relies on the water level of Lake Michigan to fluctuate only within a narrow range: too high, and the city floods with a mixture of lake water and sewage—too low, and the shipping infrastructure backing one of the country's most critical waterways is left high and dry.`}</p>
      <p>{`The water level of Lake Michigan, along with all the Great Lakes, has fluctuated annually since record keeping began over 100 years ago. Some of the biggest observed variations occurred in the early to mid 1900s, decades before the recent spike in global temperature. But unlike fluctuations in the past, periods of high water are now accompanied by more intense precipitation events, one of the byproducts of a warmer climate in the Great Lakes region.`}</p>
      <p>{`The combination of high lake water and heavy rain leaves the city more vulnerable to disastrous flooding events, several of which occurred last year when the water level was near its peak `}<span className="sup">{`[6]`}</span>{`. Looking ahead, “we can expect extremes on both ends,” according to John Allis, chief of the Army Corps of Engineers’ Great Lakes hydraulics and hydrology office `}<span className="sup">{`[7]`}</span>{`. More intense lake level and precipitation volatility adds variables to the already complex hydrologic ballet upon which the city of Chicago rests, with implications for future management of its coastline, the Chicago river, and its turbulent wastewater system.`}</p>
      <div className="mtxxl txt-center">
        <img src={Michigan} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">Water levels in Lake Michigan fluctuated from below average in January 2008 (left) to a near record high in May 2019 (right) <span className="sup">[8].</span></p>
      </div>
      <p>{`In much of the southwestern United States the trend is clearer—water is disappearing. Reservoir levels across the southwest are setting record lows as severe drought conditions enter their third straight decade. Climate forecasts predict gradually hotter and drier conditions in the region as the planet warms, leading many climate scientists to use the term aridification to describe the pattern at play `}<span className="sup">{`[9]`}</span>{`. Whereas a drought implies an eventual return to previous conditions, aridification suggests that drought `}<em>{`is`}</em>{` the new normal and that the Southwest must adapt to a future with less water.`}</p>
      <p>{`Much of the Southwest already had an unsustainable relationship with water long before the impacts of climate change were understood or observed. The Colorado River compact, for example, is an agreement signed in 1922 that allocates water to the seven states connected to the Colorado River basin. The agreement, now almost 100 years old, is based on annual water flow measures taken during exceptionally wet years in a historic climate that no longer exists. The river carried far less water on average over subsequent decades, but the compact was never adjusted `}<span className="sup">{`[10]`}</span>{`.`}</p>
      <div className="mtxxl txt-center">
        <img src={Compact} alt="" width="100%" height="100%" />
        <p className="phxl mbxxl caption">A map of lower basin states (teal) and upper basin states (purple) entitled to water under the Colorado River Compact. The Colorado River is shown at center, surrounded by the extent of its drainage basin (light shaded region).</p>
      </div>
      <p>{`Today, many states under the agreement haven’t yet built the pumps, dams, and aqueducts that would leverage their full entitlement of water; the agreement now promises far more water than it can deliver. The river’s largest reservoirs, Lake Powell and Lake Mead, have both shrunk to below half capacity over the last two decades under the combined stresses of increased demand and decreased supply. `}</p>
      <p>{`Despite demand far exceeding supply, a new pipeline proposal in aridifying Utah is seeking to tap the river even further `}<span className="sup">{`[11]`}</span>{`. While entitled to do so under the compact, drawing more water from the upper Colorado River and its tributaries would, according to Daniel McCool, a political scientist at the University of Utah, amount to “a declaration of water war” on the states downstream `}<span className="sup">{`[12]`}</span>{`.`}</p>
      <div className="mtxxl txt-center">
        <h3>Declining Levels of Lakes Powell and Mead</h3>
        <ReservoirLevels mdxType="ReservoirLevels" />
        <div className="davi-chart-bottom">
    {
            /* <div className="x-axis-label mtm">Axis Label</div> */
          }
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>Powell</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-secondary"></div>
            <div>Mead</div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">Water level declines in both lake Powell <span className="sup">[13]</span> and Mead <span className="sup">[14]</span> threaten both regional water supply as well as hydropower production <span className="sup">[15]</span>.</p>
      </div>
      <p>{`While water evaporates from the West, it inundates other parts of the country. In the Midwest, more intense rainfall and flooding events are causing billions in damage annually, overrunning drainage and levee systems designed to protect urban and agricultural infrastructure `}<span className="sup">{`[16]`}</span>{`. Heavier rain threatens cities and towns in the Northeast as well, which are often forced to release untreated waste into local waterways when their sewage systems are overwhelmed by storms. The Southeast and Caribbean are particularly vulnerable, with major coastal cities threatened by both sea level rise and more powerful storms `}<span className="sup">{`[17]`}</span>{`.`}</p>
      <p>{`Destabilized water systems set the stage for regional disputes and hard choices for policy makers. In the West where water is becoming more scarce, farmers face cutbacks to water supplies they’ve grown to depend on. Along the Klamath river in northern California and southern Oregon, tensions between farmers, federal authorities, and Indigenous communities are mounting as low water threatens clashing needs. Farmers want more water diverted from Upper Klamath Lake in order to irrigate their farms, but doing so would threaten the waterway’s salmon population, which neighboring tribes depend on and hold sacred, with extinction. `}</p>
      <p>{`In many regions, water dilemmas extend beyond agriculture. The golf industry in Phoenix Arizona, facing a potential new state plan that would impose a modest 3.1% cutback in water consumption, is organizing in opposition `}<span className="sup">{`[18]`}</span>{`. The parched state of California is already enacting water restrictions on many industrial, municipal, and commercial users `}<span className="sup">{`[19]`}</span>{`. Conversely, regions threatened with too much water face their own challenges that involve costly flood control projects, and in the most dire cases, wholesale retreat from disappearing coastlines and volatile floodplains.`}</p>
      <p>{`Forecasts depict a more intense global water cycle as the planet continues to warm, meaning even tougher choices lie ahead. As published in the Intergovernmental Panel on Climate Change (IPCC) August 2021 report, ”a warmer climate will intensify very wet and very dry weather and climate events and seasons, with implications for flooding and drought” `}<span className="sup">{`[20]`}</span>{`. Even if global greenhouse gas emissions stopped tomorrow, decades of additional warming are already locked in as the climate system, now wrapped in a thicker insulating blanket of CO2 and methane, settles into a warmer state of equilibrium. As a result, our relationship with water is certain to become increasingly volatile. Adapting our way of life, predicated on stability and control, will be our enduring challenge in a destabilized world.`}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
        <li>Paul L. Weis and William L. Newman, <a href="https://www.nps.gov/parkhistory/online_books/geology/publications/inf/72-2/index.htm" target="blank">The Channeled Scablands of Eastern Washington</a>, U.S. Department of the Interior / Geological Survey, 1976</li>
        <li>The Editors of the Montana Natural History Center, <a href="https://www.glaciallakemissoula.org/" target="blank">Glacial Lake Missoula and the Ice Age Floods</a>, Montana Natural History Center - Glacial Lake Missoula Chapter , 2021</li>
        <li>NOVA, <a href="https://www.pbs.org/wgbh/nova/article/megafloods-of-the-ice-age/" target="blank">Megafloods of the Ice Age</a>, PBS, 2005</li>
        <li>Bruce Bjornstad, <a href="https://www.instagram.com/p/BqGCAQglcqx/" target="blank">Mattawa Boulder-Bar Fan</a>, Instagram, 2018</li>
        <li>Brent Lofgren, <a href="https://www.glerl.noaa.gov/data/wlevels/levels.html#modelsAndForecasts" target="blank">Great Lakes Water Level Forecasts</a>, National Oceanic and Atmospheric Administration - Great Lakes Environmental Research Laboratory, 2021</li>
        <li><a href="https://www.regions.noaa.gov/great-lakes/index.php/highlights/record-breaking-increase-in-great-lakes-water-levels/" target="blank">Record Increase in Great Lakes Water Levels</a>, NOAA-GERL, 2021</li>
        <li>Dan Egan, <a href="https://www.nytimes.com/interactive/2021/07/07/climate/chicago-river-lake-michigan.html" target="blank">A Battle Between a Great City and a Great Lake</a>, The New York Times, 2021</li>
        <li>Ross Boissoneau, <a href="https://www.traverseticker.com/news/baykeeper-boaters-property-owners-deal-with-high-waters/" target="blank">Baykeeper, Boaters, Property Owners Deal With High Waters</a>, The Ticker, 2019</li>
        <li>Jonathan T. Overpeck and Bradley Udall, <a href="https://www.pnas.org/content/117/22/11856" target="blank">Climate change and the aridification of North America</a>, Proceedings of the National Academy of Sciences of the United States of America (PNAS), 2020</li>
        <li>Marc Reisner, <a href="https://www.penguinrandomhouse.com/books/323685/cadillac-desert-by-marc-reisner/9780140178241" target="blank">Cadillac Desert</a>, Penguin Randomhouse, 1986</li>
        <li><a href="https://lpputah.org/" target="blank">LPP Water for Today and Tomorrow</a>, Washington County Water Conservancy District, 2021</li>
        <li>Erik Stokstad, <a href="https://science.sciencemag.org/content/373/6550/17.full" target="blank">A Voice for the River</a>, Science, 2021</li>
        <li>Operations Group, <a href="https://www.usbr.gov/rsvrWater/HistoricalApp.html" target="blank">Water Operations: Historic Data</a>, United States Bureau of Reclamation, 2021</li>
        <li>Water Operations Control Center, <a href="https://www.usbr.gov/lc/region/g4000/hourly/mead-elv.html" target="blank">Lake Mead at Hoover Dam, End of Month Evlevation (Feet)</a>, United States Bureau of Reclamation, 2021</li>
        <li>Mitch Tobin, <a href="http://ecowest.org/2013/08/21/plotting-dead-pool-and-other-watersheds-for-lake-powell-and-lake-mead/" target="blank">Plotting "Dead Pool" and Other Watersheds for Lake Powell and Lake Mead</a>, EcoWest, 2013</li>
        <li>Adam B. Smith, <a href="https://www.climate.gov/news-features/blogs/beyond-data/2010-2019-landmark-decade-us-billion-dollar-weather-and-climate" target="blank">2010-2019: A Landmark Decade of U.S. Billion-Dollar Weather and Climate Disasters</a>, National Oceanic and Atmospheric Administration, 2020</li>
        <li>Carter, L., A. Terando, K. Dow, K. Hiers, K.E. Kunkel, A. Lascurain, D. Marcy, M. Osland, and P. Schramm, <a href="https://nca2018.globalchange.gov/chapter/19/" target="blank">2018: Southeast. In Impacts, Risks, and Adaptation in the United States: Fourth National Climate Assessment, Volume II</a>, U.S. Global Change Research Program, 2018</li>
        <li>Ian James, <a href="https://www.azcentral.com/story/news/local/arizona-environment/2021/06/14/arizona-golf-courses-fight-water-conservation-efforts/5032190001/" target="blank">Some Arizona golf courses are pushing back against the state's plan to reduce water use</a>, The Arizona Republic, 2021</li>
        <li>Julia Wick, <a href="https://www.latimes.com/california/story/2021-08-04/california-drought-water-restrictions-how-bad-is-it" target="blank">‘Running out of Options’: California Resorts to Water Cutoffs as Drought Worsens</a>, The Los Angeles Times, 2021</li>
        <li>[Masson-Delmotte, V., P. Zhai, A. Pirani, S. L. Connors, C. Péan, S. Berger, N. Caud, Y. Chen, L. Goldfarb, M. I. Gomis, M. Huang, K. Leitzell, E. Lonnoy, J.B.R. Matthews, T. K. Maycock, T. Waterfield, O. Yelekçi, R. Yu and B. Zhou (eds.), <a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_SPM.pdf" target="blank">IPCC, 2021: Summary for Policymakers. In: Climate Change 2021: The Physical Science Basis. Contribution of Working Group I to the Sixth Assessment Report of the Intergovernmental Panel on Climate Change</a>, Cambridge University Press, 2021</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      